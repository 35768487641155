import { Injectable } from '@angular/core'
import {
    AuthResponse,
    PostgrestMaybeSingleResponse,
    PostgrestResponse,
    PostgrestSingleResponse,
    SupabaseClient,
} from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Profile, ProfileDto, ProfileUpdateDto } from '../models/profiles.model'
import { Observable, Subscriber } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private supabase!: SupabaseClient

    constructor(private supabaseService: SupabaseService) {
        this.supabase = this.supabaseService.getClient()
    }

    profile(uid: string): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            this.supabase
                .from('profiles')
                .select('*')
                .eq('uid', uid)
                .single()
                .then((result: PostgrestMaybeSingleResponse<Profile>) => subscriber.next(result.data!))
        })
    }

    profileDetails(id: number): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            this.supabase
                .from('profiles')
                .select('*')
                .eq('id', id)
                .single()
                .then((result: PostgrestMaybeSingleResponse<Profile>) => subscriber.next(result.data!))
        })
    }

    profiles(): Observable<Profile[]> {
        return new Observable((subscriber: Subscriber<Profile[]>) => {
            this.supabase
                .from('profiles')
                .select('*')
                .then((result: PostgrestResponse<Profile>) => subscriber.next(result.data!))
        })
    }

    createProfile(profile: ProfileDto): Observable<Profile> {
        return new Observable((subscriber: Subscriber<Profile>) => {
            try {
                const { createUser, ...profileData } = profile

                this.supabase
                    .from('profiles')
                    .insert(profileData)
                    .select()
                    .then((result: PostgrestResponse<Profile>) => {
                        if (result.error != null) {
                            subscriber.error(result.error.message)
                        }
                        if (result.data?.length) {
                            if (createUser) {
                                this.createUser(profileData.email!)
                                subscriber.next(result.data![0])
                            } else {
                                subscriber.next(result.data![0])
                            }
                        }
                    })
            } catch (e) {
                return subscriber.error('Could not create profile')
            }
        })
    }

    createUser(email: string) {
        this.supabase.auth.signInWithOtp({
            email,
            options: {
                shouldCreateUser: true,
                emailRedirectTo: 'http://localhost:4200',
            },
        })
    }

    updateProfile(profile: ProfileUpdateDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            try {
                const { createUser, id, ...profileData } = profile

                const query = this.supabase
                    .from('profiles')
                    .update({ ...profileData })
                    .eq('id', id)

                query.then((result: PostgrestSingleResponse<null>) => {
                    if (result.error != null) {
                        subscriber.error(result.error.message)
                    }
                    if (result.status === 204) {
                        if (createUser) {
                            this.createUser(profileData.email!)
                            subscriber.next(true)
                        } else {
                            subscriber.next(true)
                        }
                    }
                })
            } catch (e) {
                console.warn('the error')
                console.warn(e)
                return subscriber.error('Could not create profile')
            }
        })
    }

    downLoadImage(path: string) {
        console.log(path)
        return this.supabase.storage.from('avatars').getPublicUrl(path)
    }

    uploadAvatar(filePath: string, file: File) {
        return this.supabase.storage.from('avatars').upload(filePath, file)
    }
}
